import { Box, Button, Grid, Typography } from "@mui/material";
import { serviceopg1, vider_logo_white } from "assets";
import { useNavigate } from "react-router-dom";

function ServiceViewRightMenu({ data }) {
  const navigate = useNavigate();

  const originalDateString = data?.data.updatedAt;
const originalDate = new Date(originalDateString);

// Get day, month, and year
const day = originalDate.getDate();
const month = originalDate.getMonth() + 1; // Months are zero-based
const year = originalDate.getFullYear();

// Create the formatted date string
const formattedDateString = `${day}/${month}/${year}`;
  return (
    <Box textAlign="center" width="17%" className="third-sidebar" pr={2}>
      <Grid display="flex" direction="column" alignItems="center">
        <img
          width={100}
          style={{ marginTop: "25px" }}
          src={vider_logo_white}
          alt=""
        />
      </Grid>

      <Typography variant="subtitle2" color="#4D4D4D"  p={2}>
         PROFESSIONAL SERVICES
      </Typography>
      <img src={serviceopg1} alt="" width={150} />
      <Grid
        display="flex"
        mt={2}
        alignContent="center"
        justifyContent="center"
        mb={1}
      >
        {/* <Typography
          mr={1}
          variant="caption"
          color="#8E8E8E"
          style={{ fontSize: "10px", fontWeight: "bold" }}
        >
          Vider Price:
        </Typography>
        <Typography color="#5F5F5F" fontWeight="bold" variant="body2">
          599/-
        </Typography> */}
        
              <Typography variant="body2" color='#0d47a1' mt={25}>Service Updated at: {formattedDateString}</Typography>

      </Grid>
      {/* <Button variant="contained" color="secondary" fullWidth >
        Contact Vider
      </Button> */}
    </Box>
  );
}

export default ServiceViewRightMenu;
