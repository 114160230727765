import { http } from "api/http";

const getServices = ({ queryKey }) => {
  return http.get("/admin/services", { params: { ...queryKey[1] } });
};

const createService = (data: any) => {
  return http.post("/admin/services", data);
};

const createAdminServiceInProd = (data: any) => {
  return http.post("admin/createAdminServiceInProd", data)
}

const updateAdminServiceInProd = (data: any) => {
  return http.post("admin/updateAdminServiceInProd", data)
}

const getService = ({ queryKey }) => {
  return http.get(`/services/${queryKey[1]}`);
};

const getChecklistData = ({queryKey})=>{
  return http.get(`admin/servicesChecklists/${queryKey[1]}`);
}

const getNewServices = () => {
  return http.get("/admin/newServices")
};

const updateService = ({ id, data }: any) => {
  return http.put(`/admin/services/${id}`, data);
};

const deleteService = ({ id }: any) => {
  return http.delete(`/services/${id}`);
};

export { getServices, createService, getService, updateService, deleteService,getNewServices,createAdminServiceInProd,updateAdminServiceInProd,getChecklistData };
