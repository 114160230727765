import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ServiceFaqs({ data }) {
 
  const checklistItems =data?.serviceFaqs && data?.serviceFaqs[0]?.checklistItems;
  if (!checklistItems) {
    return null;
  }
  return (
    <Box p={2} width='100%'>
        <Typography color="#0D47A1" style={{ fontWeight: "bold" }} mb={2}>
        FAQs
      </Typography>
      {checklistItems.map((item, index) => (
        <Accordion key={index} style={{ marginBottom: '8px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <Typography color="#505050" fontWeight='600' fontSize='14px'>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="#5F5F5F" fontSize='14px'>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default ServiceFaqs;
