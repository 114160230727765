import { Box, Grid, Typography } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import RecurringFrequencyDetails from "views/services/BasicDetails/RecurringFrequencyDetails";


function ServiceContentSection({ data }) {
  if (!data) {
    return null; 
  }

  const { name, hourlyPrice, totalPrice, category, subCategory,isRecurring,recurringFrequencyDetails,state } = data;

  return (
    <Box p={2} width='100%'  borderRadius="4px" padding={3}>
      <Grid container spacing={2}  bgcolor={'#fff1f3'} p={2}>
        <Grid item xs={12} >
          <Typography variant="h6" color="#0D47A1" fontWeight='600'>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
        <Typography mb={1} variant="caption" color="#8E8E8E" style={{ fontSize: '9px', fontWeight: '600' }}>
            Service Category
          </Typography>
          <Typography color='#5F5F5F' fontWeight='600' variant="body2">
            {category?.name || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
        <Typography mb={1} variant="caption" color="#8E8E8E" style={{ fontSize: '9px', fontWeight: '600' }}>
            Service Sub-Category
          </Typography>
          <Typography color='#5F5F5F' fontWeight='600' variant="body2">
            {subCategory?.name || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
        <Typography mb={1} variant="caption" color="#8E8E8E" style={{ fontSize: '9px', fontWeight: '600' }}>
            Service Jurisdriction
          </Typography>
          <Typography color='#5F5F5F' fontWeight='600' variant="body2">
            {state || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
        <Typography mb={1} variant="caption" color="#8E8E8E" style={{ fontSize: '9px', fontWeight: '600' }}>
            Type of service
          </Typography>
          <Typography color='#5F5F5F' variant="body2" fontWeight='600'>
          {isRecurring ? 'Recurring Service' : 'Non Recurring Service'}
                    </Typography>
        </Grid>
       {isRecurring && ( <Grid item xs={12} md={5}>
        <Typography mb={1} variant="caption" color="#8E8E8E" style={{ fontSize: '9px', fontWeight: '600' }}>
        {isRecurring ? 'Frequency':'' }
          </Typography>
          <Typography color='#5F5F5F' variant="body2" fontWeight='600'>
          {isRecurring ? recurringFrequencyDetails.frequency: ''}
          </Typography>
        </Grid>)}
        <Grid item xs={12} md={2}>
          <Typography mb={1} variant="caption" color="#8E8E8E" style={{ fontSize: '9px', fontWeight: '600' }}>
            Service Price
          </Typography>
          <Typography color='#5F5F5F' fontWeight='600' variant="body2">
            <span style={{ fontSize: '12px' }}>
              <CurrencyRupeeIcon sx={{ fontSize: 'inherit' }} />
              {hourlyPrice || 0}
            </span>
            &nbsp;-&nbsp;
            <span style={{ fontSize: '12px' }}>
              <CurrencyRupeeIcon sx={{ fontSize: 'inherit' }} />
              {totalPrice}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServiceContentSection;

