import { Box, Grid, Typography } from "@mui/material"
import ServiceSubTaskCard from "./ServiceSubTaskCard"

function ServiceSubTasks ({data}){

return(
    <Box p={2}>
    <Typography color="#0D47A1" style={{ fontWeight: "bold" }} mb={2}>
      Subtasks
    </Typography>
    <Grid container spacing={2}>
      {data?.subtaskServices?.map((subtaskData) => (
        <Grid item key={subtaskData.id} xs={12} sm={6}>
          <ServiceSubTaskCard data={subtaskData} />
        </Grid>
      ))}
    </Grid>
  </Box>
)
}

export default ServiceSubTasks