import { Box, TablePagination, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { ResType } from "types";
import { getQuantumRequests, getWhatsappRequests } from "api/services/organizations";
import Loader from "components/Loader";
import Table from "components/Table";
import useFilteredData from "hooks/useFilteredData";
import { useState } from "react";
import moment from "moment";
import SearchContainer from "components/SearchContainer";

const WhatsappRequests = () => {
    const [search, setSearch] = useState<string>("");

  const { data, isLoading }: ResType = useQuery(["whatsapprequests",{ search }], getWhatsappRequests);
  console.log(data)
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
console.log(search,'asdfgf')

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const hadleSearch = (e:any) => {
    setSearch(e)
  }

  if (isLoading) return <Loader />
  const paginatedData = data?.data?.slice(startIndex, endIndex);
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);

    if (![25, 50, 75, 100].includes(newRowsPerPage)) {
      setPage(0);
    }
  };
  return (
    <Box m={2}>
         <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
              <Typography variant="h5" m={1}>Whatsapp Requests</Typography>

         <SearchContainer
          minWidth="400px"
          placeHolder="Search by MobileNumber"
          onChange={setSearch}
          debounced
          value={search}
        />
    
      </Box>
      <Box mt={2}>
              <Table
        columns={[
          {
            title: "Mobile Number",
            key: "mobileNumber",
          },
          {
            title: "Message",
            key: "message",
          },
          {
            title: "Created On",
            key: "createdTimestamp",
            render: (item) => (
              <Box width={100}>
                <Typography variant="body1" color="primary">
                  {moment(item?.createdTimestamp).format("YYYY-MM-DD")}
                </Typography>
              </Box>
            ),
          },
        ]}
        loading={isLoading}
        data={paginatedData || []}
      />
       <TablePagination
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={data?.data?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}
        />
      </Box>
       
      
    


    </Box>
  );
};

export default WhatsappRequests;
