export const serviceMenu: Array<{ id: string; label: string }> = [
    { id: "overview", label: "Overview" },
    { id: "insights", label: "Insights" },
    // { id: "updates", label: "Updates" },
    // { id: "checklists", label: "Checklists" },
    // { id: "discussion-form", label: "Discussion forum" },
  ];
  
  export enum RecurringFrequency {
    MONTHLY = "monthly",
    QUARTERLY = "quarterly",
    HALF_YEARLY = "half_yearly",
    YEARLY = "yearly",
  }
  
  export enum DateTypes {
    START_DATE = "start_date",
    STATUTORY_DATE = "status_date",
    EXPECTED_DATE = "expected_date",
  }

  export enum DscApplicationStatus {
    APPLICATION_SUBMITTED = 'application_submitted',
    PENDING_AT_EMUDHRA = 'pending_at_emudhra',
    DSC_ISSUED = 'dsc_issued',
    NONE = 'NONE',
  }