import { Add } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { getCategories } from "api/services/categories";
import { getTemplateCategories } from "api/services/quantumTemplate";
import Loader from "components/Loader";
import SearchContainer from "components/SearchContainer";
import useFilteredData from "hooks/useFilteredData";
import useTitle from "hooks/useTitle";
import { useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import AddQuantumCategory from "views/quantumCategories/AddQuantumCategory";
import QuantumCategoryCard from "views/quantumCategories/QuantumCategoryCard";

function QuantumCategories() {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const { data, isLoading }: ResType = useQuery("quantum-categories", getTemplateCategories);

  const filteredData = useFilteredData(data?.data, ["name"], search);

  useTitle("Document Categories");

  if (isLoading) return <Loader />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "sticky",
          top: 60,
          backgroundColor: "white",
          zIndex: 2,
          borderBottom: "1px solid #e0e0e0",
          pt: 2,
          pb: 2,
          mb: 2,
          mx: -2,
          px: 2,
        }}
      >
        <SearchContainer
          minWidth="400px"
          placeHolder="Search for a document category"
          onChange={setSearch}
        />
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          startIcon={<Add />}
          color="secondary"
        >
          Add Document Category
        </Button>
      </Box>
      <Grid container spacing={2}>
        {filteredData?.map((item: any, index: any) => (
          <Grid item xs={4} key={index}>
            <QuantumCategoryCard data={item} />
          </Grid>
        ))}
      </Grid>
      <AddQuantumCategory open={open} setOpen={setOpen} />
    </Box>
  );
}

export default QuantumCategories;
