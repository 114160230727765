import { MoreVert } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useMenu } from "context/MenuPopover";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { StyledChecklistItem } from "views/services/Checklists/styles";

interface Props {
  data: any;
  checklistIndex: number;
  index: number;
}

const ServiceCheckListItemView = ({ data, checklistIndex, index }: Props) => {
  const dispatch = useDispatch();
  const menu = useMenu();
  const [open, setOpen] = useState<boolean>(false);

 
  return (
    <>
      <StyledChecklistItem
        bgcolor={(index + 1) % 2 === 1 ? "#FAFAFA" : "white"}
      >
        <Grid container sx={{ flex: 1 }}>
          <Grid item xs={5}>
            <Typography variant="body1">{data.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <div>
              <Typography variant="body2">{data?.description}</Typography>
            </div>
          </Grid>
        </Grid>
      </StyledChecklistItem>
     
    </>
  );
};

export default ServiceCheckListItemView;
