
import { ArrowRightAltOutlined, MoreVert } from "@mui/icons-material";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomCard from "components/CustomCard";
import { useNavigate } from "react-router-dom";

function ServiceMilestoneCard({ data }) {

  const navigate = useNavigate();
  
  return (
    <CustomCard
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: '0px 0px 5px 0px #00000040',
        backgroundColor: data?.type === 'STAGE_OF_WORK' ? '#c1ffb6' : '#b6fff4',
      }}
    >
      <Box flex={1}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            sx={{ flex: 1 }}
            mb={1}
            color="#0D47A1" style={{fontWeight:'700',fontSize:'14px'}}
          >
            {data?.name}
          </Typography>
        </Box>
        <Typography color="#5F5F5F" fontSize='12px' fontWeight='700' >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></div>
        </Typography>
      </Box>
      <Box>
        
      </Box>
    </CustomCard>
  );
}



function ServiceMilestones ({data}){
return(
    <Box p={2}>
    <Typography color="#0D47A1" style={{ fontWeight: "bold" }} mb={2}>
      Milestones
    </Typography>
    <Grid container spacing={2}>
      {data?.stageOfWorks?.map((linkedService) => (
        <Grid item key={linkedService.id} xs={12} sm={6}>
          <ServiceMilestoneCard data={linkedService} />
        </Grid>
      ))}
    </Grid>
  </Box>
)
}

export default ServiceMilestones