import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface LoaderProps {
  minHeight?: string | number;
  color?: string;
  text?: boolean;
}

function Loader(props: LoaderProps) {
  const { minHeight = 300, color = "primary.main" } = props;
  return (
    <Box
      sx={{
        minHeight,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress sx={{ color }} />{Text? <Typography sx={{marginLeft: '10px'}}>Loading...</Typography> : null}
    </Box>
  );
}

export default Loader;
