import { Chip, Typography, Box } from "@mui/material";

function ServiceLabels({ data }) {
  return (
    <Box  p={2}>
      <Typography  mb={1} color="#3124B6" style={{ fontWeight: "bold" }}>
        Labels
      </Typography>
      {data?.labels &&
        data.labels.map((label) => (
          <Chip
            key={label.id}
            label={label.name}
            variant="outlined"
            style={{ marginRight: 8, marginBottom: 8, }}
          />
        ))}
    </Box>
  );
}

export default ServiceLabels;
