import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ServiceCheckListView from './ServiceCheckListView';
import _ from "lodash";

function ServiceChecklists({ data }) {
  return (
    <div>
      <Typography color="#3124B6" style={{ fontWeight: "bold" }}  mb={2}>
        Checklists
      </Typography>
      <Box mt={2}>
        {data?.checklists && (
          _.sortBy(data?.checklists, "id").map((item: any, index: number) => (
            <ServiceCheckListView data={item} key={index} index={index} />
          ))

        )}
      </Box>
    </div>
  );
}

export default ServiceChecklists;
