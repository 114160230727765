import { ArrowRightAltOutlined, MoreVert } from "@mui/icons-material";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomCard from "components/CustomCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";
import { getService } from "api/services/services";
import { useQuery } from "react-query";
import { ResType } from "types";

function ServiceAssociatedCard({ data }) {

  const { data:serviceData, isLoading }: ResType = useQuery(
    ["service-details", data.id],
    getService,
  );

  return (
    <CustomCard
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: '0px 0px 5px 0px #00000040'

      }}
    >
      <Box flex={1}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            sx={{ flex: 1 }}
            mb={1}
            color="#0D47A1" style={{fontWeight:'700',fontSize:'14px'}}
          >
            {data?.name}
          </Typography>
        </Box>
        <Typography color="#5F5F5F" fontSize='12px' fontWeight='700' >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></div>
        </Typography>
      </Box>
      <Box>
        <Divider sx={{ my: 1 }} />
        <Box textAlign="right" color='#545454' fontWeight='700' display='flex' justifyContent='space-between' alignItems='center'>
          <Typography color="#0D47A1" style={{fontWeight:'700',fontSize:'14px'}}>
            <span style={{ fontSize: '12px' }}>
              <CurrencyRupeeIcon sx={{ fontSize: 'inherit' }} />
              {serviceData?.data?.hourlyPrice}
            </span>
            &nbsp;-&nbsp;
            <span style={{ fontSize: '12px' }}>
              <CurrencyRupeeIcon sx={{ fontSize: 'inherit' }} />
              {serviceData?.data?.totalPrice}
            </span>
          </Typography>
          <Button
            onClick={() => {
              //  navigate(`/services/add/?serviceId=${data?.id}`);
              window.open(`/services/overview/?serviceId=${data?.id}`);

            }}
            endIcon={<ArrowRightAltOutlined />}
            style={{
              fontSize:'10px',
              fontWeight:'700',
              color:'#545454'
            }}
          >
            View details
          </Button>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default ServiceAssociatedCard;
