import { Box, Button, TablePagination, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { getAtomProLimitRequests, responseForAtomProLimit } from "api/services/organizations";
import Loader from "components/Loader";
import Table from "components/Table";
import { useConfirm } from "context/ConfirmDialog";
import moment from "moment";

const AtomProLimitRequests = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [page, setPage] = useState(0);

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const queryClient = useQueryClient();
    const confirm = useConfirm()
    const { data, isLoading }: ResType = useQuery("atomProLimit", getAtomProLimitRequests);
    const { mutate, isLoading: isResponseLoading } = useMutation(responseForAtomProLimit, {
        onSuccess: (res) => {
            queryClient.invalidateQueries("atomProLimit");
        }
    })

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);

        if (![10, 25, 50, 75, 100].includes(newRowsPerPage)) {
            setPage(0);
        }
    };

    const paginatedData = data?.data?.slice(startIndex, endIndex);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleResponseLimitStatus = (row: any) => {
        confirm({
            msg: "Did you really increase the client limit for this organization?",
            action: () => {
                mutate({ id: row?.id, data: row })

            }
        })
    }
    if (isLoading || isResponseLoading) return <Loader />
    return (
        <Box m={2}>
            <Typography variant="h5" m={1}>Atom Pro Client Request Limits</Typography>
            <Table
                columns={[
                    {
                        title: "Organization Id",
                        key: "user.organization.id",
                    },
                    {
                        title: "user Name",
                        key: "user.fullName",
                    },
                    {
                        title: "Organization Name",
                        key: "user.organization.legalName",
                    },
                    {
                        title: "Mobile Number",
                        key: "user.organization.mobileNumber",
                    },
                    {
                        title: "Email",
                        key: "user.organization.email",
                    },
                    {
                        title: "Created At",
                        key: "createdAt",
                        render: (row) => <Typography>{moment(row?.createdAt).format("DD-MM-YYYY HH:mm")}</Typography>
                    },
                    {
                        title: "Respond status",
                        key: "respondStatus",
                        render: (row) => <Button
                            disabled={row?.respondStatus}
                            onClick={() => handleResponseLimitStatus(row)}
                        >
                            Response Status
                        </Button>
                    },
                    {
                        title: "Request types",
                        key: "id",
                        render: (row) => <Box>
                            {row?.requestTypes && (
                                row?.requestTypes?.map((item: any) => (
                                    <span>{item} , </span>
                                ))
                            )}
                        </Box>
                    }
                ]}
                loading={isLoading}
                data={paginatedData || []}
                sx={{ height: "550px" }}
            />
            <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100]}
                component="div"
                count={data?.data?.length || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}
            />
        </Box>
    );
};

export default AtomProLimitRequests;
