import { Box, Grid, Typography } from "@mui/material";

function ServiceProcedureSteps({ data }) {
  
  const checklistItems =data?.serviceProcedure && data?.serviceProcedure[0]?.checklistItems;
  if (!checklistItems) {
    return null;
  }

  return (
    <Box  >
      <Typography color="#0D47A1" style={{ fontWeight: "bold" }} mb={2}>
        Procedure{" "}
      </Typography>
      {checklistItems.map((item, index) => (
        <Box key={index} display="flex" justifyContent="space-around">
          <Box mr={3}>
            <Typography
              variant="h6"
              color="#000000"
              bgcolor="#E3F2FD"
              width="80px"
              p={1}
              textAlign="center"
              borderRadius={1}
            >
             Step 0{index + 1}
            </Typography>
            {index !== checklistItems.length - 1 &&
              [...Array(5)].map((_, dotIndex) => (
                <Typography key={dotIndex} variant="body2" color="#D9D9D9" textAlign="center">
                  |
                </Typography>
              ))}
          </Box>
          <Box>
            <Typography color="#3124B6" style={{ fontWeight: "600" }} mb={1} mt={1}>
              {item.title}
            </Typography>
            <Typography
              color="#5F5F5F"
              dangerouslySetInnerHTML={{ __html: item.description }}
              textAlign="justify"
              fontWeight='600'
              fontSize='12px'
              lineHeight='22px'
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default ServiceProcedureSteps;
