import { Box, Grid, Typography } from "@mui/material"
import ServiceAssociatedCard from "./ServiceAssociatedCard"

function ServicesAssociated({data}){
  if (!data || !data.linkedServices || data.linkedServices.length === 0) {
    return null; 
  }
    return(
        <Box p={2}>
        <Typography color="#0D47A1" style={{ fontWeight: "bold" }} mb={2}>
          Associated Services
        </Typography>
        <Grid container spacing={2}>
          {data?.linkedServices?.map((linkedService) => (
            <Grid item key={linkedService.id} xs={12} sm={6}>
              <ServiceAssociatedCard data={linkedService} />
            </Grid>
          ))}
        </Grid>
      </Box>
    )

}

export default ServicesAssociated