import { http } from "api/http";

const getQuantumTemplates = () => {
    return http.get("/admin/quantumTemplates");
}

const getQuantumTemplate = ({ queryKey }) => {
    return http.get(`/admin/quantumTemplates/${queryKey[1]}`)
}

const createQuantumTemplate = (data: any) => {
    return http.post("/admin/quantumTemplate", data)
}

const updateQuantumTemplate = ({ id, data }: any) => {
    return http.put(`/admin/quantumTemplate/${id}`, data)
}

const deleteQuantumTemplate = (id: number) => {
    return http.delete(`/admin/quantumTemplate/${id}`)
}

const getTemplateCategories = (data: any) => {
    return http.get("/admin/quantumCategoires");
}

const updateQuantumCategory = ({ id, data }: any) => {
    return http.put(`/admin/quantumCategoires/${id}`, data)
}

const deleteQuantumCategory = (id: number) => {
    return http.delete(`/admin/quantumCategoires/${id}`)
}

const createQuantumCategory = (data: any) => {
    return http.post("/admin/createQuantumCategories", data);
};

const getNewQtmTemplates = (data: any) => {
    return http.get("/admin/newQtmTemplates");
};

const createAdminQtmTemplateInProd = (data: any) => {
    return http.post("/admin/createAdminQtmTemplateInProd", data);
};

const updateAdminQtmTemplateInProd= (data: any) => {
    return http.put("/admin/updateAdminQtmTemplateInProd", data);
};

export {
    createQuantumTemplate,
    getQuantumTemplates,
    getQuantumTemplate,
    updateQuantumTemplate,
    deleteQuantumTemplate,
    getTemplateCategories,
    createQuantumCategory,
    deleteQuantumCategory,
    updateQuantumCategory,
    getNewQtmTemplates,
    createAdminQtmTemplateInProd,
    updateAdminQtmTemplateInProd
};