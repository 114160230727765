import { ChatOutlined, DeleteOutlined } from "@mui/icons-material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { Box, Button, Breadcrumbs, Typography } from "@mui/material";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { serviceMenu } from "data/constants";
import { StyledProfileNav, StyledProfileNavItem } from "./styles";
import BreadCrumbs from "components/BreadCrumbs";

function ServiceHeader({ id}) {

  const [activeMenuItem, setActiveMenuItem] = useState("overview");
  const navigate = useNavigate();
  const ServiceMenu = () => {
    return serviceMenu;
  };

  const pathParts = window.location.pathname.split('/');
  const handleMenuItemClick = (item) => {
    setActiveMenuItem(item.id);
    if(item.id === 'insights'){
      navigate(`/services/insights/?serviceId=${id.id}`);
    }else if(item.id === 'overview'){
      navigate(`/services/overview/?serviceId=${id.id}`);
    }
  };
  return (
    <Box position="sticky"  top={62} zIndex={2}>
      <Box pl={2} bgcolor='#ffffff' >
      <BreadCrumbs page="Service Details" />
      </Box>
      <Box
        p={2}
        bgcolor="white"
        display="flex"
        justifyContent="space-between"
      ></Box>
      <StyledProfileNav>
        {ServiceMenu().map((item, index) => (
          <StyledProfileNavItem
            variant="body2"
            onClick={() => handleMenuItemClick(item)}
            key={index}
            active={pathParts[2] === item.id ? 1 : 0}
          >
            {item.label}
          </StyledProfileNavItem>
        ))}
      </StyledProfileNav>
    </Box>
  );
}

export default ServiceHeader;
