import {
  Box,
  Grid,
} from "@mui/material";
import { getService } from "api/services/services";
import BreadCrumbs from "components/BreadCrumbs";
import Loader from "components/Loader";
import useQueryParams from "hooks/useQueryParams";
import useTitle from "hooks/useTitle";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  setData,
} from "redux/reducers/addServiceSlice";
import { ResType } from "types";
import "./services/AddService.css";
import ServiceHeader from "views/service-view/ServiceHeader";
import ServiceContentSection from "views/service-view/ServiceContentSection";
import ServiceOverview from "views/service-view/ServiceOverview";
import ServicesAssociated from "views/service-view/ServiceAssociated";
import ServiceViewRightMenu from "views/service-view/ServiceViewRightMenu";
import ServiceViewLeftMenu from "views/service-view/ServiceViewLeftMenu";
import ServiceProcedureSteps from "views/service-view/ServiceProcedureSteps";
import ServiceFaqs from "views/service-view/ServiceFaqs";
import ServiceInsightsLeftMenu from "views/service-view/ServiceInsightsLeftMenu";

function ServiceInsights() {
  useTitle("services-info");
  const dispatch = useDispatch();
  const { queryParams } = useQueryParams();
  let serviceId = queryParams.serviceId;

  const { data, isLoading }: ResType = useQuery(
    ["service-details", serviceId],
    getService,
    {
      onSuccess: (res: any) => {
        dispatch(setData(res?.data));
      },
      enabled: !!serviceId,
    }
  );

  useEffect(() => {
    if (!serviceId) {
    }
  }, [serviceId, data, dispatch]);


  if (isLoading) return <Loader />;

  const scrollToSection = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href")?.substring(1);
    const targetSection = document.getElementById(targetId || "");

    if (targetSection) {  
      
    const offset = targetSection.offsetTop; 
    const headerHeight = 160; 

    window.scrollTo({
      top: offset - headerHeight,
      behavior: "smooth",
    });
   
    }
  };
  

  return (
    <>
        <ServiceHeader id={data.data}/>
        <Box pt={1} pb={15} pl={5}>
          <Box display="flex">
            <Box
              sx={{
                width: 200,
                // minHeight: 650,
                // height:'100vh'
              }}
            >
            <ServiceInsightsLeftMenu scrollToSection={scrollToSection} />
            </Box>
            <Grid container width="65%">
              <Box id="procedure-steps" mb={2}>
              {(data?.data.serviceProcedure !== null && (Array.isArray(data?.data.serviceProcedure) && data?.data.serviceProcedure.length !== 0)) && <ServiceProcedureSteps data={data.data} />}
              </Box>

              <Box id="faqs" mb={2} width='100%'>
              {data?.data?.serviceFaqs && <ServiceFaqs data={data.data} />}
              </Box>
              <Box id="associated-services" mb={2} width='100%' >
              {data?.data?.linkedServices && <ServicesAssociated data={data.data} />}
              </Box>
             
            </Grid>
           <ServiceViewRightMenu data={data}/>
          </Box>
          {/* <Paper
            elevation={2}
            sx={{
              ml: 0,
              position: "fixed",
              right: 0,
              bottom: 0,
              width: "100%",
              zIndex: "100",
              transition: "0.8s",
            }}
          >
            <Box p={2} display="flex" justifyContent="flex-end" gap={2}>
              <GreyButton
                onClick={() => {
                  navigate("/services");
                }}
                size="large"
                color="secondary"
                variant="contained"
              >
                Cancel
              </GreyButton>
            </Box>
          </Paper> */}
        </Box>
    </>
  );
}

export default ServiceInsights;
