import { Add, KeyboardArrowDown, KeyboardArrowUp, MoreVert } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { snack } from "components/toast";
import { useMenu } from "context/MenuPopover";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteChecklist,
  updateChecklistName,
} from "redux/reducers/addServiceSlice";
import CheckListItem from "./ServiceChecklistItemView";
import _ from "lodash";
import { StyledChecklist } from "views/services/Checklists/styles";
import ServiceCheckListItemView from "./ServiceChecklistItemView";

type Props = {
  data: any;
  index: number;
};

const ServiceCheckListView = ({ data, index }: Props) => {

  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  
  return (
    <>
      <StyledChecklist>
        <header>
          <Typography variant="h6">
            <div
              contentEditable
              suppressContentEditableWarning={true}
            >
              {data?.name}
            </div>
          </Typography>
          <Box display="flex" gap={1}>
          <IconButton onClick={() => setShow(!show)}>
              {show? <KeyboardArrowUp /> : <KeyboardArrowDown/>}
            </IconButton>
          </Box>
        </header>
        {show && (
          <Box>
            {_.sortBy(data?.checklistItems, "id").map((item: any, itemIndex: number) => (
              <ServiceCheckListItemView
                data={item}
                checklistIndex={index}
                index={itemIndex}
                key={itemIndex}
              />
            ))}
          </Box>
        )}
      </StyledChecklist>
    </>
  );
};

export default ServiceCheckListView;
