import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { IAddService } from "./types";
import { CLIENT_CATEGORIES } from "utils/constants";

const initialClientSubCategory = {}; // Initialize an empty object for clientSubCategory
CLIENT_CATEGORIES.forEach((clientCategory) => {
  initialClientSubCategory[clientCategory.label] = {
    label: clientCategory.label,
    value: clientCategory.value,
    subCategories: clientCategory?.subCategories,
  };
});

const initialState: IAddService = {
  name: "",
  description: "",
  category: null,
  subCategory: null,
  hourlyPrice: "",
  totalPrice: "",
  checklists: [],
  milestones: [],
  stageOfWork: [],
  subTasks: [],
  state: "",
  clientSubCategory: initialClientSubCategory, // Initialize with the JSON data
  linkedServices:[],
  content:"",
  prismServiceName:"",
  prismImage:"",
  prismImageUrl:"",
  prismSampleCertificate:"",
  prismSampleCertificateUrl:"",
  prismProcessImage:"",
  prismProcessImageUrl:"",
  applicationType:"",
  postIsActive:false,
  prismYoutubeLink:"",
  prismChecklists:[],
  prismPrice:"",
  prismDescription:"",
  serviceFaqs:[],
  serviceProcedure:[],
  subtaskServices:[],
  isActive:false,
  labels:[],
  isRecurring:false,
  frequency:null,
  recurringFrequencyDetails:null,
};  

export const addServiceSlice = createSlice({
  name: "addService",
  initialState,
  reducers: {
    setData: (state: IAddService, action) => {
      const { payload } = action;
      state.name = payload.name;
      state.description = payload.description || "";
      state.category = payload.category?.id || null;
      state.subCategory = payload.subCategory?.id || null;
      state.hourlyPrice = payload.hourlyPrice || "";
      state.totalPrice = payload.totalPrice || "";
      state.checklists = payload.checklists;
      state.milestones = payload.milestones;
      state.stageOfWork = payload.stageOfWorks;
      state.subTasks = payload.subTasks;
      state.state = payload.state || "";
      state.clientSubCategory = payload.clientSubCategory || {};
      state.linkedServices = payload.linkedServices || [];
      state.content = payload.content || "";
      state.prismServiceName = payload.prismServiceName || "";
      state.prismImage = payload.prismImage || "";
      state.prismImageUrl = payload.prismImageUrl || "";
      state.prismSampleCertificate = payload.prismSampleCertificate || "";
      state.prismSampleCertificateUrl = payload.prismSampleCertificateUrl || "";
      state.prismProcessImage = payload.prismProcessImage || "";
      state.prismProcessImageUrl = payload.prismProcessImageUrl || "";
      state.applicationType = payload.applicationType;
      state.postIsActive = payload.postIsActive;
      state.prismYoutubeLink = payload.prismYoutubeLink;
      state.prismChecklists = payload.prismChecklists || [];
      state.prismPrice = payload.prismPrice || "";
      state.prismDescription = payload.prismDescription || "";
      state.serviceFaqs = payload.serviceFaqs || [];
      state.serviceProcedure = payload.serviceProcedure || [];
      state.subtaskServices = payload.subtaskServices || [];
      state.isActive=payload.isActive;
      state.labels = payload.labels || [];
      state.isRecurring = payload.isRecurring;
      state.frequency = payload.frequency;
      state.recurringFrequencyDetails= payload.recurringFrequencyDetails;
    },
    resetData: (state: IAddService) => {
      state.name = "";
      state.description = "";
      state.category = null;
      state.subCategory = null;
      state.hourlyPrice = "";
      state.totalPrice = "";
      state.checklists = [];
      state.milestones = [];
      state.stageOfWork = [];
      state.subTasks = [];
      state.state = "";
      state.clientSubCategory = initialClientSubCategory;
      state.linkedServices = [];
      state.content = "";
      state.prismServiceName = "";
      state.prismImage = "";
      state.prismImageUrl="";
      state.prismSampleCertificate = "";
      state.prismSampleCertificateUrl="";
      state.prismProcessImage = "";
      state.prismProcessImageUrl="";
      state.applicationType = "";
      state.postIsActive = false;
      state.prismYoutubeLink = "";
      state.prismChecklists= [];
      state.prismPrice = "";
      state.prismDescription="";
      state.serviceFaqs=[];
      state.serviceProcedure = [];
      state.subtaskServices=[];
      state.isActive=false;
      state.labels = [];
      state.isRecurring = false;
      state.frequency = null;
      state.recurringFrequencyDetails=null;
    },
    handleChange: (state: IAddService, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    addChecklist(state: IAddService, action) {
      state.checklists.push({...action.payload,checklistAdded:true});
    },
    updateChecklistName(state: IAddService, action) {
      state.checklists[action.payload.index].name = action.payload.name;
      state.checklists[action.payload.index]['checklistUpdated'] = true;
    },
    deleteChecklist(state: IAddService, action) {
      state.checklists.splice(action.payload, 1);
    },
    addChecklistItem(state: IAddService, action) {
      state.checklists[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
      
      state.checklists[action.payload.index]['itemUpdated']=true;
    },
    deleteChecklistItem(state: IAddService, action) {
      state.checklists[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
      state.checklists[action.payload.checklistIndex]['itemUpdated']=true;
    },
    updateChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.checklists[checklistIndex].checklistItems[itemIndex] = {...data,itemUpdated:true};
      state.checklists[checklistIndex]['itemUpdated']=true;
    },
    addMilestone(state: IAddService, action) {
      state.milestones.push(action.payload);
    },
    updateMilestone(state: IAddService, action) {
      state.milestones[action.payload.index] = action.payload.data;
    },
    deleteMilestone(state: IAddService, action) {
      state.milestones.splice(action.payload, 1);
    },
    addStageOfWork(state: IAddService, action) {
      state.stageOfWork.push(action.payload);
    },
    updateStageOfWork(state: IAddService, action) {
      state.stageOfWork[action.payload.index] = action.payload.data;
    },
    deleteStageOfWork(state: IAddService, action) {
      state.stageOfWork.splice(action.payload, 1);
    },
    addSubTask(state: IAddService, action) {
      state.subTasks.push(action.payload);
    },
    updateSubTask(state: IAddService, action) {
      state.subTasks[action.payload.index] = action.payload.data;
    },
    deleteSubTask(state: IAddService, action) {
      state.subTasks.splice(action.payload, 1);
    },
   
    addLinkedServices(state: IAddService, action) {
      state.linkedServices.push(action.payload);
    },

    removeLinkedService(state: IAddService, action) {
     state.linkedServices.splice(action.payload,1)
    },

    addApplicationType (state: IAddService, action) {
      state.applicationType = action.payload; 
    },

    togglePostIsActive(state: IAddService) {
      state.postIsActive = !state.postIsActive; 
    },

    toggleIsActive(state:IAddService){
      state.isActive = !state.isActive;
    },

    //SUBTASK SERVICES//
    addSubtaskServices(state: IAddService, action) {
      state.subtaskServices.push(action.payload);
    },

    removeSubtaskService(state: IAddService, action) {
     state.subtaskServices.splice(action.payload,1)
    },

     //SERVICE PROCEDURE
     addProChecklist(state: IAddService, action) {
      state.serviceProcedure.push(action.payload);
    },
    updateProChecklistName(state: IAddService, action) {
      state.serviceProcedure[action.payload.index].name = action.payload.name;
    },
    deleteProChecklist(state: IAddService, action) {
      state.serviceProcedure.splice(action.payload, 1);
    },
    addProChecklistItem(state: IAddService, action) {
      state.serviceProcedure[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
    },
    deleteProChecklistItem(state: IAddService, action) {
      state.serviceProcedure[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
    },
    updateProChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.serviceProcedure[checklistIndex].checklistItems[itemIndex] = data;
    },

     //SERVICE FAQS 
     addFaqChecklist(state: IAddService, action) {
      state.serviceFaqs.push(action.payload);
    },
    updateFaqChecklistName(state: IAddService, action) {
      state.serviceFaqs[action.payload.index].name = action.payload.name;
    },
    deleteFaqChecklist(state: IAddService, action) {
      state.serviceFaqs.splice(action.payload, 1);
    },
    addFaqChecklistItem(state: IAddService, action) {
      state.serviceFaqs[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
    },
    deleteFaqChecklistItem(state: IAddService, action) {
      state.serviceFaqs[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
    },
    updateFaqChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.serviceFaqs[checklistIndex].checklistItems[itemIndex] = data;
    },

     //PRISM CHECKLIST 
     addPrismChecklist(state: IAddService, action) {
      state.prismChecklists.push(action.payload);
    },
    updatePrismChecklistName(state: IAddService, action) {
      state.prismChecklists[action.payload.index].name = action.payload.name;
    },
    deletePrismChecklist(state: IAddService, action) {
      state.prismChecklists.splice(action.payload, 1);
    },
    addPrismChecklistItem(state: IAddService, action) {
      state.prismChecklists[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
    },
    deletePrismChecklistItem(state: IAddService, action) {
      state.prismChecklists[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
    },
    updatePrismChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.prismChecklists[checklistIndex].checklistItems[itemIndex] = data;
    },

    setInitialClientSubCategory: (state: IAddService) => {
      const initialClientSubCategory = {};
      CLIENT_CATEGORIES.forEach((clientCategory) => {
        initialClientSubCategory[clientCategory.label] = {
          label: clientCategory.label,
          value: clientCategory.value,
          subCategories: [],
        };
      });
      state.clientSubCategory = initialClientSubCategory;
    },
    setRecurringFrequencyDetails: (state: IAddService, action) => {
      state.recurringFrequencyDetails = action.payload;
    },
  },
});

export const addServiceState = (state: RootState) => state.addService;

export const {
  addChecklist,
  addChecklistItem,
  updateChecklistName,
  deleteChecklist,
  deleteChecklistItem,
  updateChecklistItem,
  addMilestone,
  updateMilestone,
  deleteMilestone,
  addStageOfWork,
  updateStageOfWork,
  deleteStageOfWork,
  addSubTask,
  updateSubTask,
  deleteSubTask,
  handleChange,
  setData,
  addLinkedServices,
  removeLinkedService,
  addApplicationType,
  togglePostIsActive,
  resetData,
   ////////////////
   addPrismChecklist,
   addPrismChecklistItem,
   updatePrismChecklistName,
   deletePrismChecklist,
   deletePrismChecklistItem,
   updatePrismChecklistItem,
   ///////////////
   ////////////////
   addFaqChecklist,
   addFaqChecklistItem,
   updateFaqChecklistName,
   deleteFaqChecklist,
   deleteFaqChecklistItem,
   updateFaqChecklistItem,
   ///////////////
   ////////////////
   addProChecklist,
   addProChecklistItem,
   updateProChecklistName,
   deleteProChecklist,
   deleteProChecklistItem,
   updateProChecklistItem,
   ///////////////
   addSubtaskServices,
   removeSubtaskService,
   toggleIsActive,
   setRecurringFrequencyDetails
} = addServiceSlice.actions;

export default addServiceSlice.reducer;
