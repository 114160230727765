import { Box, Grid, Typography } from "@mui/material";
import { pink } from "@mui/material/colors";

function ServiceOverview({ data }) {
  if (!data || !data.description) {
    return null;
  }


  const modifiedDescription = data?.description.replace(/style="color: rgb\(0, 0, 0\);"/g, 'style="color: #5F5F5F;"');

  return (
    <Grid p={3} width="100%">
      <Grid>
        <Typography color="#3124B6" style={{ fontWeight: "bold" }}>
          Description
        </Typography>
         <Typography   textAlign='justify' fontWeight='600' fontSize='13px' lineHeight='20px'>
          <div
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          ></div>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ServiceOverview;
