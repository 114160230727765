import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";

interface ServiceMenuProps {
  scrollToSection: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
interface MenuItem {
  href: string;
  text: string;
}

const menuItems: MenuItem[] = [
  {href:'#basic-details', text:'Basic Details'},
  { href: "#overview", text: "Description" },
  { href: "#checklists", text: "Checklists" },
  { href: "#milestones", text: "Milestones" },
  { href: "#subtasks", text: "Subtasks" },
];

const ServiceViewLeftMenu: React.FC<ServiceMenuProps> = ({
  scrollToSection,
}) => {
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

  const handleMenuItemClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    scrollToSection(e);
    setActiveMenuItem(href);
  };
  return (
   
    <Box
      className="second-sidebar"
      sx={{ borderRight: "1px solid #e0e0e0", minHeight: 650, height: "100vh" }}
    >
       <Box display='flex' flexDirection='column' >
       {menuItems.map((item) => (
          <React.Fragment key={item.href}>
            <Divider sx={{ my: 0 }} />
            <a href={item.href} onClick={(e) => handleMenuItemClick(e, item.href)} style={{ backgroundColor: activeMenuItem === item.href ? " #e5e3e3" : "inherit", borderRight: activeMenuItem === item.href ? "3px solid #9f0707" : 'none' }}>
              <Typography>{item.text}</Typography>
            </a>
          </React.Fragment>
        ))}
    </Box>
    {/* <Box style={{zIndex:1, marginTop:'170px'}}>
    <Divider sx={{ my: 0 }} />
      <Typography color='secondary' variant="body2" paddingLeft='60px' mb={1}>Rasie a Ticket</Typography>
      <Typography color='secondary' variant="body2" paddingLeft='60px'>Talk to Vider</Typography>
      <Divider sx={{ my: 0 }} />
    </Box> */}
    </Box>
  );
};

export default ServiceViewLeftMenu;
